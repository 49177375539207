import { createSlice } from '@reduxjs/toolkit';
import { emptySplitApi } from './api/v1/base';

const initialState = {
  school: [],
  grade: [],
  subject: [],
};

export const autocomplete = createSlice({
  name: 'autocomplete',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      emptySplitApi.endpoints.getFilterValues.matchFulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { originalArgs },
          },
        }
      ) => {
        state[originalArgs] = payload;
      }
    );
  },
});

export const getSchools = (state) => state[autocomplete.name].school;
export const getGrades = (state) => state[autocomplete.name].grade;
export const getSubjects = (state) => state[autocomplete.name].subject;
