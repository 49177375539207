import { map } from 'lodash';
import { transformToLocalTz } from '../../../../shared/utils/format';
import { emptySplitApi } from './base';
import { v1URL } from './formatter';

const tutorsURL = v1URL('tutors');
const shiftURL = v1URL('shiftAvailability');

function transformResponse(response) {
  const {
    result,
    paginationInfo: { pageSize, currentPage },
  } = response;
  const offset = pageSize * currentPage;
  const transformRow = (row, index) => {
    const seqNo = index + offset + 1;
    const id = index + offset + 1;
    const startDt = row?.startDate
      ? transformToLocalTz({ date: row?.startDate, time: row?.startTime })
      : null;
    return { ...row, seqNo, id, startDt };
  };
  return { ...response, result: map(result, transformRow) };
}

const tutorsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getTutors: builder.mutation({
      query: (body) => ({
        url: tutorsURL('getAlltutorList'),
        method: 'POST',
        body,
      }),
      transformResponse,
    }),
    getTutorNames: builder.query({
      query: ({ sessionId }) => ({
        url: tutorsURL(`getTutorNames?sessionId=${sessionId}`),
        method: 'GET',
      }),
    }),
    getTutorStatusById: builder.mutation({
      query: (body) => ({
        url: tutorsURL('tutorStatus'),
        method: 'POST',
        body,
      }),
    }),
    getDashboardTutorStatus: builder.query({
      query: (currentDateTimeInUTC) => ({
        url: tutorsURL('getDashboardTutorStatus'),
        params: { currentDateTimeInUTC },
      }),
    }),
    assignTutorToSession: builder.mutation({
      query: (arg) => ({
        url: tutorsURL('assignTutorToSession'),
        method: 'POST',
        body: arg,
      }),
    }),
    // Tutoring team
    getTutoringTeams: builder.query({
      query: () => ({
        url: tutorsURL('tutoringTeams'),
      }),
    }),
    getTutorsByRoleId: builder.query({
      query: (params) => ({
        url: tutorsURL('tutoringTeamsRoleList'),
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getTutorsByUserId: builder.query({
      query: (params) => ({
        url: tutorsURL('tutoringTeamsTutorList'),
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, arg) => [{ type: 'Post', id: arg.userId }],
    }),
    AssignAndUnAssignTeam: builder.mutation({
      query: (body) => ({
        url: tutorsURL('tutoringTeamsAssignUnAssign'),
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.managerId }],
    }),
    ReassignTutor: builder.mutation({
      query: (body) => ({
        url: tutorsURL('tutoringTeamsReAssign'),
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Post' }],
    }),
    TutorReassignList: builder.mutation({
      query: (body) => ({
        url: tutorsURL('tutoringTeamsReAssignList'),
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.userId }],
    }),
    TutorShiftAvalibilty: builder.mutation({
      query: (body) => ({
        url: tutorsURL('getTutorShiftAvailability'),
        method: 'POST',
        body,
      }),
    }),
    saveShiftAvailability: builder.mutation({
      query: (body) => ({
        url: shiftURL('saveShiftAvailability'),
        method: 'POST',
        body,
      }),
    }),
    TutorsTimeSlot: builder.mutation({
      query: (body) => ({
        url: tutorsURL('tutorTimeSlot'),
        method: 'POST',
        body,
      }),
    }),
    getTutorsTeamList: builder.mutation({
      query: (body) => ({
        url: tutorsURL('teamlist'),
        method: 'POST',
        body,
      }),
    }),
    getTutoringTeamsDownload: builder.mutation({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: tutorsURL('tutoringTeamsDownload'),
          params,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement('a');
        const url = window.URL || window.webkitURL;
        const blobPDF = url.createObjectURL(result.data);
        hiddenElement.href = blobPDF;
        hiddenElement.target = '_blank';
        hiddenElement.download = `tutoring_teams.xlsx`;
        hiddenElement.click();
        return { data: null };
      },
    }),
    getSessionHistory: builder.mutation({
      query: (body) => ({
        url: tutorsURL('sessionHistory'),
        method: 'POST',
        body,
      }),
      transformResponse,
    }),
    getSessionHistoryAll: builder.mutation({
      query: (body) => ({
        url: tutorsURL('sessionHistoryAll'),
        method: 'POST',
        body,
      }),
      transformResponse,
    }),
  }),
});

export const {
  useGetTutorsMutation,
  useGetTutorStatusByIdMutation,
  useGetDashboardTutorStatusQuery,
  useAssignTutorToSessionMutation,
  useGetTutorNamesQuery,
  useGetTutoringTeamsQuery,
  useGetTutorsByRoleIdQuery,
  useGetTutorsByUserIdQuery,
  useAssignAndUnAssignTeamMutation,
  useReassignTutorMutation,
  useTutorShiftAvalibiltyMutation,
  useTutorsTimeSlotMutation,
  useTutorReassignListMutation,
  useSaveShiftAvailabilityMutation,
  useGetTutorsTeamListMutation,
  useGetTutoringTeamsDownloadMutation,
  useGetSessionHistoryMutation,
  useGetSessionHistoryAllMutation,
} = tutorsApi;
