import { emptySplitApi } from './base';
import { v1URL } from './formatter';

const usersURL = v1URL('users');

const usersApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => '/api/v1/users',
    }),
    getUserBySearch: builder.query({
      query: (nameStartsWith) => ({
        url: usersURL('search'),
        params: {
          nameStartsWith,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getUserRolesByUserId: builder.query({
      query: (arg) => usersURL(`userRoles/${arg.userId}`),
      // eslint-disable-next-line default-param-last
      providesTags: (result = [], error, arg) => [
        'userRoles',
        ...result.map(({ roleId }) => ({ type: 'userRoles', roleId })),
      ],
      keepUnusedDataFor: 0,
    }),
    getPermissionsByRoleId: builder.query({
      query: (arg) => usersURL(`rolePermissions/${arg.roleId}`),
      keepUnusedDataFor: 0,
      // eslint-disable-next-line default-param-last
      providesTags: (result = [], error, arg) => [
        'rolePermissions',
        ...result.map(({ roleId }) => ({ type: 'rolePermissions', roleId })),
      ],
    }),
    getRoles: builder.query({
      query: () => usersURL('roles'),
    }),
    getPermissions: builder.query({
      query: () => usersURL('permissions'),
    }),
    getCurrentUser: builder.query({
      query: () => usersURL('myInfo'),
      providesTags: ['currentUser'],
    }),
    assignUserRole: builder.mutation({
      query: (arg) => ({
        url: usersURL('assignUserRoles'),
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'userRoles', roleId: arg.roleIds[0] }],
    }),
    assignRolePermissions: builder.mutation({
      query: (arg) => ({
        url: usersURL('assignRolePermissions'),
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'rolePermissions', roleId: arg.roleId }],
    }),
    updateCurrentUser: builder.mutation({
      query: (dashboard) => ({
        url: usersURL('updateUser'),
        method: 'POST',
        body: {
          dashboard,
        },
      }),
      invalidatesTags: (result) => [{ type: 'currentUser' }],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useGetRolesQuery,
  useGetUserBySearchQuery,
  useGetUserRolesByUserIdQuery,
  useGetPermissionsByRoleIdQuery,
  useAssignUserRoleMutation,
  useGetPermissionsQuery,
  useAssignRolePermissionsMutation,
  useUpdateCurrentUserMutation,
} = usersApi;
