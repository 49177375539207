import { Grid, Rating, Typography } from '@mui/material';
import React from 'react';

const sizes = {
  small: '0.875rem',
  medium: '1rem',
  large: '1.2rem',
};
function StarRating(props) {
  return (
    <Grid container direction="row">
      <Grid
        item
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography fontSize={sizes[props.size]}>{props.name}</Typography>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: 1,
        }}
      >
        <Rating precision={0.5} {...props} />
      </Grid>
    </Grid>
  );
}

export default StarRating;
