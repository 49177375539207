/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function UpcomingSessionIcon(props) {
  return (
    <SvgIcon
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.99707 2.5C2.99707 1.83696 3.26046 1.20107 3.7293 0.732233C4.19814 0.263392 4.83403 0 5.49707 0H14.4971C15.1601 0 15.796 0.263392 16.2648 0.732233C16.7337 1.20107 16.9971 1.83696 16.9971 2.5V11.5C16.9971 12.163 16.7337 12.7989 16.2648 13.2678C15.796 13.7366 15.1601 14 14.4971 14H10.7431C10.9961 13.693 11.2171 13.358 11.4001 13H14.4971C14.8949 13 15.2764 12.842 15.5577 12.5607C15.839 12.2794 15.9971 11.8978 15.9971 11.5V4H9.49707C9.09925 4 8.71771 3.84196 8.43641 3.56066C8.1551 3.27936 7.99707 2.89782 7.99707 2.5V1H5.49707C5.09925 1 4.71771 1.15804 4.43641 1.43934C4.15511 1.72064 3.99707 2.10218 3.99707 2.5V5.601C3.64077 5.78366 3.30546 6.00463 2.99707 6.26V2.5ZM15.9971 3V2.5C15.9971 2.10218 15.839 1.72064 15.5577 1.43934C15.2764 1.15804 14.8949 1 14.4971 1H8.99707V2.5C8.99707 2.63261 9.04975 2.75979 9.14352 2.85355C9.23729 2.94732 9.36446 3 9.49707 3H15.9971Z" />
      <path
        d="M5.5 16C7.98528 16 10 13.9853 10 11.5C10 9.01472 7.98528 7 5.5 7C3.01472 7 1 9.01472 1 11.5C1 13.9853 3.01472 16 5.5 16Z"
        fill="#FFF"
        stroke="#777777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 8.92856V11.5L6.78571 12.7857"
        stroke="#777777"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default UpcomingSessionIcon;
