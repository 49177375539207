import _ from 'lodash';

import { toServerDate } from '../../../../shared/utils';
import { transformToLocalTz } from '../../../../shared/utils/format';
import { emptySplitApi } from './base';
import { v1URL } from './formatter';

function transformResponse(response) {
  const {
    result,
    paginationInfo: { pageSize, currentPage },
  } = response;
  const offset = pageSize * currentPage;
  const transformRow = (row, index) => {
    const { startDate, startTime } = row;
    const startDt = transformToLocalTz({ date: startDate, time: startTime });
    const id = index + offset + 1;

    return { ...row, id, startDt };
  };
  return { ...response, result: _.map(result, transformRow) };
}

function isPresent(value) {
  return value && _.trim(value);
}

function buildEndpoint(builder, url) {
  const method = 'POST';

  function query({ pageIndex, pageSize, filterType, filterValue, searchByName }) {
    const currentDateTimeInUTC = toServerDate();
    const paginationCriteria = {
      pageSize,
      pageNumber: pageIndex,
    };

    const body = {
      currentDateTimeInUTC,
      paginationCriteria,
    };

    if (isPresent(filterType) && isPresent(filterValue)) {
      body.filterCriteria = [{ field: filterType, value: filterValue }];
    }
    if (isPresent(searchByName)) {
      body.searchByName = searchByName;
    }

    return {
      url,
      method,
      body,
    };
  }

  return builder.mutation({ query, transformResponse });
}

const sessionsURL = v1URL('sessions');
const sessionsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    searchOngoing: buildEndpoint(builder, sessionsURL('ongoingSession')),
    searchNotification: buildEndpoint(builder, sessionsURL('notificationSession')),
    searchUpcomingNotification: buildEndpoint(builder, sessionsURL('upcomingNotification')),
    getFilterValues: builder.query({
      query: (filterName) => sessionsURL(`getFilterValues/${filterName}`),
    }),
    getComments: builder.mutation({
      query: (body) => ({
        url: sessionsURL('comments'),
        method: 'POST',
        body,
      }),
      transformResponse,
    }),
    getUpcomingSessions: buildEndpoint(builder, sessionsURL('upcomingSession')),
    getSessionsCount :  builder.query({
      query: () => ({
      
          url: sessionsURL('sessionsCount'),
          params: {
            currentDateTimeInUTC: toServerDate(),
          },
       
      }),
    }),
    getSessionComments : builder.query({
      query: (id) => ({
        url: sessionsURL('getSessionComments'),
        params: {
          sessionId:id
        }
      })
    })
  }),
});

export const {
  useSearchOngoingMutation,
  useSearchNotificationMutation,
  useSearchUpcomingNotificationMutation,
  useGetFilterValuesQuery,
  useGetUpcomingSessionsMutation,
  useGetCommentsMutation,
  useGetSessionsCountQuery,
  useGetSessionCommentsQuery
} = sessionsApi;
