export const dtFormat = 'DD-MM-YY HH:mm';
export const activeFontWeight = '600';

export const PAGE_CONFIG = {
  size: 20,
};

export const PERMISSIONS = {
  NOTIFICATIONS: 'NOTIFICATIONS',
  ONGOING_SESSIONS: 'ONGOING SESSIONS',
  UPCOMING_SESSIONS: 'UPCOMING SESSIONS',
  TUTOR_AVAILABILITY: 'TUTOR AVAILABILITY',
  MANAGEMENT: 'MANAGEMENT',
  REPORTS: 'REPORTS',
  SCHEDULE_CALENDER: 'SCHEDULE CALENDAR',
  DOT_ANALYSIS: 'DOT ANALYSIS',
  SESSION_HISTORY: 'SESSION HISTORY',
};

export const SESSION_TYPE = {
  ONGOING: 'ongoing',
  UPCOMING: 'upcoming',
};

export const TUTOR_STATUS = {
  AVAILABLE: 'Available',
  IN_SESSION: 'Insession',
  OFFLINE: 'Offline',
};

export const SESSION_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  DISCONNECTED: 'Disconnected',
  UNASSIGNED: 'UnAssigned',
  ONREQUEST: 'OnRequest',
  UNKNOWN: 'Unknown',
  LIVE: 'Live',
};
