import { Box, Card, Popover, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import IconBadge from '../assets/icons/IconBadge';
import TutorStatus from '../pages/Dashboard/components/TutorStatus';
import { useGetTutorOverviewQuery } from '../redux/slices/api/v1/dashboard';
import CanShow from '../shared/components/CanShow';

function TutorStatusOverview() {
  const { isLoading, data } = useGetTutorOverviewQuery();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Box>
      <Card
        sx={{
          ml: 6,
          p: 1,
          px: 3,
          display: { xs: 'flex' },
          alignItems: 'center',
        }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Typography fontFamily="fontBold" fontSize={16} noWrap component="div">
          Tutor Status Overview
        </Typography>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <QtyBox
            isLoading={isLoading}
            status="Available"
            value={data?.available}
            icon={<IconBadge color={['#3FD266', '#34A853']} />}
          />
          <QtyBox
            isLoading={isLoading}
            status="Offline"
            value={data?.offline}
            icon={<IconBadge color={['#777777', '#3C3C3C']} />}
          />
          <QtyBox
            isLoading={isLoading}
            status="In Session"
            value={data?.inSession}
            icon={<IconBadge color={['#FF0000', '#BF392E']} />}
          />
        </Box>
      </Card>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          zIndex: 9999999999999,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <TutorStatus width="300px" />
      </Popover>
    </Box>
  );
}

function QtyBox({ status, value, icon, isLoading }) {
  return (
    <Box
      sx={{
        mx: 1.75,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
      }}
    >
      {icon}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          ml: 1,
        }}
      >
        <CanShow
          show={!isLoading}
          fallbackComponent={<Skeleton variant="text" animation="wave" width={30} />}
        >
          <Typography
            fontFamily="fontExtraBold"
            lineHeight={1}
            fontSize={22}
            noWrap
            component="div"
          >
            {value || 0}
          </Typography>
        </CanShow>
        <Typography fontSize={12} noWrap component="div">
          {status}
        </Typography>
      </Box>
    </Box>
  );
}
export default TutorStatusOverview;
