import { Box, Card, Toolbar } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

function AppContent() {
  return (
    <Box component="main" sx={{ flexGrow: 1, mx: 2, width: 0, height: '100vh' }}>
      <Toolbar />
      <Card
        sx={{
          p: '0.625rem',
          height: '88%',
          borderRadius: 2,
          overflow: 'auto',
        }}
      >
        <Outlet />
      </Card>
    </Box>
  );
}

export default AppContent;
