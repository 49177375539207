import { map, mapValues } from 'lodash';
import { toServerDate } from '../../../../shared/utils';
import { transformToLocalTz } from '../../../../shared/utils/format';
import { emptySplitApi } from './base';
import { v1URL } from './formatter';

function transformResponse(response) {
  const transformRow = (row) => {
    const { date, time } = row;

    const startDate = transformToLocalTz(
      { date: date?.split('-'), time: time?.split(':') },
      'hh:mm'
    );
    return { ...row, startDate };
  };

  const data = mapValues(response, (v) => (v.length > 0 ? map(v, (s) => transformRow(s)) : []));
  return data;
}
const dashboardURL = v1URL('dashboard');

const dashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: (currentDateTimeInUTC) => ({
        url: dashboardURL('sessionsCount'),
        params: { currentDateTimeInUTC },
      }),
    }),
    getTutorAvailabilityCount: builder.query({
      query: () => dashboardURL('tutorAvailability'),
    }),
    getSessionAnalytics: builder.query({
      query: (params) => {
        const { sessionType, subject } = params;
        const queryParams = {
          sessionType,
          currentDateTimeInUTC: toServerDate(),
        };
        // Only include subject parameter if it's not null
        if (subject !== null) {
          queryParams.subject = subject;
        }
        return {
          url: dashboardURL('sessionAnalytics'),
          params: queryParams,
        };
      },
      //  This is to disable the cache for this API.
      keepUnusedDataFor: 0,
      transformResponse,
    }),
    getTutorOverview: builder.query({
      query: () => dashboardURL('tutorStatusOverview'),
    }),
    getAvailableTutorList: builder.query({
      query: (params) => ({
        url: dashboardURL('availableTutors'),
        params,
      }),
    }),
    getLiveNotifications: builder.query({
      query: (params) => ({
        url: dashboardURL('liveNotifications'),
        params,
      }),
    }),
    getCommentRoll: builder.query({
      query: (params) => ({
        url: dashboardURL('commentRoll'),
        params,
      }),
    }),
  }),
});

export const {
  useGetDashboardQuery,
  useGetTutorOverviewQuery,
  useGetSessionAnalyticsQuery,
  useGetTutorAvailabilityCountQuery,
  useGetAvailableTutorListQuery,
  useGetLiveNotificationsQuery,
  useGetCommentRollQuery,
} = dashboardApi;
