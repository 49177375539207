import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import IconTutor from '../../../assets/icons/IconTutor';
import { colors } from '../../../theme/colors.styles';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    mb: 2,
  },
};
function CardContainer({
  children,
  icon = <IconTutor fontSize="24" color={colors.primary.main} />,
  title,
  height = '100%',
  overflowY,
  color = colors.text.primary,
  background = '#ffffff',
  titleStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightComponent,
}) {
  return (
    <Card sx={{ height, p: 2, background, color,overflowY }}>
      <Box sx={styles.container}>
        <Box sx={{ ...styles.titleContainer, ...titleStyle }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {icon}
            <Typography
              fontFamily="fontBold"
              fontSize="1.2rem"
              noWrap
              component="div"
              marginLeft={1.5}
            >
              {title}
            </Typography>
          </Box>
          {rightComponent}
        </Box>
        {children}
      </Box>
    </Card>
  );
}

export default CardContainer;
