import styled from '@emotion/styled';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Badge,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';

import MuiDrawer from '@mui/material/Drawer';
import { isEmpty } from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useGetSessionsCountQuery } from '../redux/slices/api/v1/sessions';
import { HasAuthority } from '../shared/components/HasAuthority';
import { SIDEBAR_WIDTH } from '../theme/dims';
import styles from './AppSidebar.styles.css';
import { nav } from './_nav';

const NavLinkMui = React.forwardRef((props, forwardedRef) => (
  <NavLink {...props} ref={forwardedRef} />
));
const openedMixin = (theme) => ({
  marginTop: '4.9rem',
  // borderRadius: 10,
  left: '0.93rem',
  height: '88%',
  width: SIDEBAR_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: 'transparent',
  borderRight: 0,
});

const closedMixin = (theme) => ({
  marginTop: '4.9rem',
  backgroundColor: 'transparent',
  left: '0.93rem',
  height: '88%',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  borderRight: 0,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: SIDEBAR_WIDTH,
  flexShrink: 0,
  marginRight: '0.93rem',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function AppSidebar({ open, toggleDrawer }) {
  const theme = useTheme();
  const { data } = useGetSessionsCountQuery();

  return (
    <div className="AppSidebar">
      <Drawer variant="permanent" open={open}>
        <Card sx={{ height: '100%', px: 0.8 }}>
          <List>
            <ListItem key="menu" disablePadding onClick={toggleDrawer} sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.1,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <MenuIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Menu"
                  color="primary"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: 'primary.main',
                    fontFamily: 'fontBold',
                  }}
                />
              </ListItemButton>
            </ListItem>
            {nav.map(({ title, icon: Icon, path, permission, countKey, exact }, index) => (
              <HasAuthority permissions={permission} key={`nav-mi${index}`}>
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    component={NavLinkMui}
                    to={path}
                    end={exact}
                    className={styles.ListItemButton}
                    sx={{
                      minHeight: 56,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      borderRadius: 2,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon
                        // viewBox="0 0 16 16"
                        fontSize="16"
                      />
                    </ListItemIcon>
                    <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
                    <ListItemIcon sx={{ minWidth: '10px' }}>
                      {countKey !== '' && !isEmpty(data) && open && (
                        <Badge
                          size="large"
                          badgeContent={data[countKey]}
                          color="secondary"
                          max={100000}
                          sx={{
                            '& .MuiBadge-badge': {
                              background: '#acacac',
                              fontSize: '0.56rem',
                              fontFamily: 'fontBold',
                              padding: '0.25rem',
                              borderRadius: '0.25rem',
                            },
                          }}
                        />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </HasAuthority>
            ))}
          </List>
        </Card>
      </Drawer>
    </div>
  );
}

export default AppSidebar;
