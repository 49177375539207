export const colors = {
  type: 'light',
  primary: {
    main: '#0086C3',
  },
  secondary: {
    main: '#777777',
    dark: '#D9D9D9',
  },
  info: {
    main: '#75E7C9',
  },
  warning: {
    main: '#FBBC05',
  },
  error: {
    main: '#EA4335',
  },
  success: {
    main: '#34A853',
  },
  dark: {
    main: '#000000',
  },
  background: {
    default: '#F5F8FE',
  },
  text: {
    primary: '#404040',
    secondary: '#777777',
    caption: '#828282',
  },
  hover: {
    default: '#F5F8FE',
  },
  IconColor: {
    star: '#FBBC05',
    notes: '#EA4335',
    ilp: '#59A540',
    view: '#EF9625',
    swap: '#952566',
    sessionHistory: '#EF9625',
    tutorStats: '#59A540',
  },
  linearBgColor: 'linear-gradient(92.12deg, #0086C3 0.89%, #6B1145 100%)',
};
