/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function SortDesc(props) {
  return (
    <SvgIcon
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.5">
        <path
          d="M11.4606 5.332H7.52856V4.641L10.1076 0.921V0.867H7.59856V0H11.3836V0.691L8.81656 4.411V4.465H11.4616V5.332H11.4606Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.58263 10.961L8.16463 12.332H7.09863L8.88763 7H10.1216L11.9026 12.332H10.7826L10.3636 10.961H8.58263ZM10.1526 10.176L9.50063 8.02H9.45363L8.80163 10.176H10.1526Z"
          fill="black"
        />
        <path
          d="M3.00063 11.832C3.00063 11.9646 2.94795 12.0918 2.85418 12.1856C2.76042 12.2793 2.63324 12.332 2.50063 12.332C2.36802 12.332 2.24085 12.2793 2.14708 12.1856C2.05331 12.0918 2.00063 11.9646 2.00063 11.832V2.039L0.854632 3.186C0.808144 3.23249 0.752954 3.26936 0.692215 3.29452C0.631476 3.31968 0.566375 3.33263 0.500632 3.33263C0.434888 3.33263 0.369788 3.31968 0.309048 3.29452C0.248309 3.26936 0.19312 3.23249 0.146632 3.186C0.100144 3.13951 0.0632673 3.08432 0.0381082 3.02358C0.0129491 2.96284 -1.09529e-09 2.89774 0 2.832C1.09529e-09 2.76625 0.0129491 2.70115 0.0381082 2.64041C0.0632673 2.57967 0.100144 2.52449 0.146632 2.478L2.14663 0.478998L2.15363 0.471998C2.24763 0.380701 2.37383 0.330133 2.50486 0.331256C2.6359 0.332379 2.76121 0.385103 2.85363 0.477998L4.85363 2.478C4.90012 2.52442 4.93701 2.57954 4.96219 2.64022C4.98738 2.7009 5.00036 2.76595 5.00041 2.83164C5.00046 2.89734 4.98756 2.96241 4.96246 3.02312C4.93737 3.08383 4.90055 3.13901 4.85413 3.1855C4.80771 3.23199 4.75258 3.26888 4.69191 3.29406C4.63123 3.31924 4.56618 3.33223 4.50049 3.33228C4.43479 3.33232 4.36972 3.31943 4.30901 3.29433C4.2483 3.26923 4.19312 3.23242 4.14663 3.186L3.00063 2.039V11.832Z"
          fill="black"
        />
      </g>
    </SvgIcon>
  );
}

export default SortDesc;
