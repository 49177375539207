import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
// eslint-disable-next-line max-len
import SnackbarContextProvider from './shared/contexts/Snackbar/SnackbarContextProvider.Provider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarContextProvider>
        <CssBaseline />
        {/* <React.StrictMode> */}
        <Router>
          <App />
        </Router>
        {/* </React.StrictMode> */}
      </SnackbarContextProvider>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
