import { ArrowBack, FilterAltOff, FilterAltOutlined } from '@mui/icons-material';

import {
  Box,
  DialogContent,
  InputAdornment,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { capitalize, debounce, map } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import GlobalSearch from '../../shared/components/GlobalSearch';
import { useGetSessionHistoryMutation } from '../../redux/slices/api/v1/tutors';
import SortAsc from '../../assets/icons/SortAsc';
import SortDesc from '../../assets/icons/SortDesc';
import DropdownSelect from '../../shared/components/Dropdowns/DropdownSelect';
import SessionHistoryIcon from '../../assets/icons/SessionHistoryIcon';
import CustomModal from '../../shared/components/Modal';
import PageContainer from '../../shared/components/PageContainer';
import PageHeader from '../../shared/components/PageHeader';
import { PAGE_CONFIG } from '../../shared/constants/constants';
import useModal from '../../shared/hooks/useModal';
import { toServerDate } from '../../shared/utils';
import { globalStyles } from '../../theme/global.styles';
import TutorStats from './components/TutorStats';
import upcomingSessionColumns from '../UpcomingSession/columns';
import { getGrades, getSchools } from '../../redux/slices/autocomplete.slice';
import Loader from '../../shared/components/Loader';

const defaultFilters = {
  page: 1,
  size: PAGE_CONFIG.size,
  searchByName: '',
};

function SessionHistory(props) {
  const [getSessionHistory, { data: response, isLoading }] = useGetSessionHistoryMutation();
  const { data: tutor, open, openModal, closeModal } = useModal();
  const [data, setData] = React.useState({ result: [], paginationInfo: {} });
  const [filters, setFilters] = useState(defaultFilters);
  const [sort, setSort] = useState([]);
  const { rowInfo, setShowHistory } = props;
  const [filterType, setFilterType] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [searchByName, setSearchByName] = useState('');
  const school = useSelector(getSchools);
  const grade = useSelector(getGrades);
  const autocompletes = {
    school,
    grade,
  };

  const fetchSessionHistory = async () => {
    const { page, size, ...restFilters } = filters;
    try {
      const res = await getSessionHistory({
        filterCriteria: filterType !== null ? [{ field: filterType, value: filterValue }] : null,
        paginationCriteria: { pageNumber: page - 1, pageSize: size },
        sortCriteria: null,
        searchByName,
        currentDateTimeInUTC: toServerDate(),
        tutorId: rowInfo?.tutorId,
      }).unwrap();
      setData(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    fetchSessionHistory();
  }, [filters, sort, searchByName]);

  const handleRowClick = null;
  const sessionHistoryCols = upcomingSessionColumns({ dateKey: 'Date', handleRowClick }).filter(
    (e) => e.accessorKey !== 'tutor' && e.id !== 'action'
  );

  const columns = React.useMemo(() => sessionHistoryCols, [data?.result]);

  const table = useReactTable({
    data: data?.result || [],
    columns,
    state: {
      pageIndex: filters?.page,
      pageSize: filters?.size,
      sorting: sort,
    },
    // onGlobalFilterChange: setGlobalFilter,
    // globalFilterFn: fuzzyFilter,
    // Pipeline
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSort,
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleFilters = (newFilter, clear) => {
    clear ? setFilters(null) : setFilters({ ...filters, ...newFilter });
  };

  const handleSearch = useMemo(
    () =>
      debounce((searchByName) => {
        handleFilters({ searchByName });
        setSearchByName(searchByName);
      }, 300),
    []
  );

  return (
    <PageContainer sx={globalStyles.tablePage}>
      <PageHeader
        icon={
          rowInfo !== undefined ? (
            <ArrowBack onClick={() => setShowHistory(false)} />
          ) : (
            <SessionHistoryIcon width="26" />
          )
        }
        title="Session History"
      >
        <GlobalSearch
          value={searchByName ?? ''}
          onChange={(value) => handleSearch(String(value))}
          placeholder="Search"
        />
        <DropdownSelect
          variant="standard"
          sx={{ color: 'primary.main', padding: '5px', fontFamily: 'fontSemiBold' }}
          value={filterType}
          onChange={(v) => {
            setFilterValue(null);
            setFilterType(v.id);
          }}
          options={map(['school', 'grade'], (v) => ({ id: v, label: capitalize(v) }))}
          startAdornment={
            <InputAdornment position="start" sx={{ color: 'text.primary' }}>
              <FilterAltOutlined fontSize="14px" color="dark" />{' '}
              <Typography fontFamily="fontSemiBold">Filter by</Typography>
            </InputAdornment>
          }
        />
        <DropdownSelect
          variant="standard"
          sx={{ color: 'primary.main', padding: '5px', fontFamily: 'fontSemiBold' }}
          value={filterValue}
          onChange={(v) => {
            setFilters({ ...filters, page: 1, size: 20 });
            setFilterValue(v);
          }}
          options={map(autocompletes[filterType], ({ value }) => ({
            id: value,
            label: value,
          }))}
          startAdornment={
            <InputAdornment position="start" sx={{ color: 'text.primary' }}>
              <FilterAltOutlined fontSize="14px" color="dark" />{' '}
            </InputAdornment>
          }
        />
        <FilterAltOff
          sx={{ mt: '8px' }}
          onClick={() => {
            setFilterType(null);
            setFilterValue(null);
            setFilters({ ...filters, page: 1, size: 20 });
          }}
        />
      </PageHeader>
      {isLoading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      <TableContainer sx={{ height: '82%' }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            {table.getHeaderGroups()?.map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers?.map((header) => (
                  <TableCell
                    sx={header.column?.columnDef?.sx}
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    <Box display="flex" alignItems="center">
                      {flexRender(header.column.columnDef.header, header.getContext())}{' '}
                      {{
                        asc: <SortAsc sx={{ width: '14px', ml: 0.5 }} />,
                        desc: <SortDesc sx={{ width: '14px', ml: 0.5 }} />,
                      }[header.column.getIsSorted()] ?? null}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {data?.result?.length > 0 ? (
            <TableBody>
              {table.getRowModel()?.rows?.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells()?.map((cell) => (
                    <TableCell
                      sx={cell.column?.columnDef?.sx}
                      align={cell.column?.columnDef?.align}
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            !isLoading && (
              <TableBody>
                <TableRow key="table-row-no-data">
                  <TableCell colSpan={columns.length} key="table-cell-no-data">
                    <div style={{ textAlign: 'center', color: 'rgb(145, 145, 153)' }}>
                      No Data Available
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            )
          )}
        </Table>
      </TableContainer>
      {data?.result?.length > 0 && (
        <Box marginTop={2} display="flex" justifyContent="flex-end">
          <Pagination
            count={data?.paginationInfo?.totalPages}
            rowsPerPage={filters?.size}
            defaultPage={0}
            shape="rounded"
            variant="outlined"
            color="primary"
            page={filters?.page}
            onChange={(_, page) => {
              handleFilters({ page });
            }}
          />
        </Box>
      )}
      {open && tutor && (
        <CustomModal close={closeModal} open={open} maxWidth="lg" fullWidth>
          <DialogContent>
            <TutorStats id={tutor?.tutorId} />
          </DialogContent>
        </CustomModal>
      )}
    </PageContainer>
  );
}

export default SessionHistory;
