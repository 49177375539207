import fontBold from '../assets/fonts/Nunito-Bold.ttf';
import fontExtraBold from '../assets/fonts/Nunito-ExtraBold.ttf';
import fontLight from '../assets/fonts/Nunito-Light.ttf';
import fontMedium from '../assets/fonts/Nunito-Medium.ttf';
import fontRegular from '../assets/fonts/Nunito-Regular.ttf';
import fontSemiBold from '../assets/fonts/Nunito-SemiBold.ttf';

export const MuiCssBaseline = {
  styleOverrides: `
      @font-face {
          font-family: 'fontRegular';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Nunito'), local('Nunito-Regular'), url(${fontRegular}) format('truetype');
      }
      @font-face {
          font-family: 'fontMedium';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Nunito'), local('Nunito-Medium'), url(${fontMedium}) format('truetype');
      }
      @font-face {
          font-family: 'fontSemiBold';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Nunito'), local('Nunito-SemiBold'), url(${fontSemiBold}) format('truetype');
      }
      @font-face {
          font-family: 'fontBold';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Nunito'), local('Nunito-Bold'), url(${fontBold}) format('truetype');
      }
      @font-face {
          font-family: 'fontLight';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Nunito'), local('Nunito-Light'), url(${fontLight}) format('truetype');
      }
      @font-face {
          font-family: 'fontExtraBold';
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('Nunito'), local('Nunito-ExtraBold'), url(${fontExtraBold}) format('truetype');
      }
    `,
};
