import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { getBgColorFromStatus, getTitleColorFromStatus } from '../utils';

function StatusBadge({ label }) {
  return (
    <Button
      size="small"
      variant="contained"
      disableRipple
      disableFocusRipple
      disableElevation
      sx={{
        backgroundColor: getBgColorFromStatus(label),
        color: getTitleColorFromStatus(label),
        fontSize: '0.725rem',
        padding: '3px',
        ':hover': {
          backgroundColor: getBgColorFromStatus(label),
          color: getTitleColorFromStatus(label),
          cursor: 'text',
        },
      }}
    >
      {label}
    </Button>
  );
}

StatusBadge.propTypes = {
  label: PropTypes.string.isRequired,
};

export default memo(StatusBadge);
