import { useState } from 'react';

const useModal = () => {
  const [data, setData] = useState(null);
  const [open, onOpenModal] = useState(false);
  const [close, onCloseModal] = useState(false);

  const openModal = (d) => {
    onOpenModal(true);
    d && setData(d);
  };

  const closeModal = () => {
    onCloseModal(true);
    onOpenModal(false);
    setData(null);
  };

  return { data, open, close, openModal, closeModal };
};

export default useModal;
