import { Box } from '@mui/material';
import React from 'react';
import AppContent from './AppContent';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';

function AppLayout() {
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <AppHeader open={open} handleDrawerOpen={handleDrawerOpen} />
      <AppSidebar open={open} toggleDrawer={toggleDrawer} />
      <AppContent />
    </Box>
  );
}

export default AppLayout;
