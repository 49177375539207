import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

function TableAction({ title, onClick, Icon, viewBox, fill }) {
  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick}>
        <Icon
          viewBox={viewBox}
          sx={{
            fontSize: '20px',
            color: `#cccccc`,
            '&:hover': {
              color: `${fill}`,
            },
          }}
        />
      </IconButton>
    </Tooltip>
  );
}

export default TableAction;
