import { configureStore } from '@reduxjs/toolkit';
import { authenticationApi } from './slices/api/authentication';
import { emptySplitApi } from './slices/api/v1/base';

import { authenticationMiddleware } from './middleware';
import { authSlice } from './slices/auth.slice';
import { autocomplete } from './slices/autocomplete.slice';
import { currentUser } from './slices/currentUser.slice';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [authSlice.name]: authSlice.reducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    [autocomplete.name]: autocomplete.reducer,
    [currentUser.name]: currentUser.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authenticationMiddleware,
      authenticationApi.middleware,
      emptySplitApi.middleware
    ),
});
