import { Box, Card, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useGetCommentRollQuery } from '../redux/slices/api/v1/dashboard';
import { toServerDate } from '../shared/utils';
import { formatName } from '../shared/utils/format';

const params = {
  currentDateTimeInUTC: toServerDate(),
};

function CommentOverview() {
  const divRef = useRef(null);
  const { data } = useGetCommentRollQuery(params, { pollingInterval: 300000 });
  const [commentList, setCommentList] = useState(data);

  useEffect(() => {
    const interval = setInterval(() => {
      if (commentList !== undefined) {
        const conList = [...commentList];
        conList.push(conList.shift());
        setCommentList([...conList]);
      }
    }, 3000);

    return function cleanup() {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    scrollToBottom();
  }, [commentList]);

  useEffect(() => {
    if (!isEmpty(data)) {
      setCommentList(data);
    }
  }, [data]);

  const scrollToBottom = () => {
    divRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Card
      sx={{
        display: { xs: 'flex' },
        alignItems: 'center',
        p: 1,
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Grid container rowSpacing={1} height="48px" sx={{ overflowY: 'auto' }}>
          {commentList?.map((e, index) => (
            <Grid padding={1} key={index} container>
              <Grid item xs={12}>
                <Typography alignItems="center" fontSize={12}>
                  &quot;{e?.comments}&quot;
                </Typography>
              </Grid>
              <Grid item xs={6} marginTop={1}>
                <Typography fontFamily="fontExtraBold" fontSize={12}>
                  {formatName(e)}
                </Typography>
                <Typography fontSize={12}>{`School: ${e?.school}`}</Typography>
              </Grid>
              <Grid item xs={6} marginTop={1}>
                <Typography fontSize={12}>{`Ses ID: ${e?.sessionId}`}</Typography>
                <Typography fontSize={12}>{`Grade: ${e?.grade}`}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <div ref={divRef} />
      </Box>
    </Card>
  );
}

export default CommentOverview;
