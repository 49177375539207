import {
  AppBar,
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Divider,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as AppLogo } from '../assets/images/logo.svg';
import { setToken } from '../redux/slices/auth.slice';
import { getUserDetails, getUserRoles } from '../redux/slices/currentUser.slice';
import { formatName } from '../shared/utils/format';
import CommentOverview from './CommentOverview';
import TutorStatusOverview from './TutorStatusOverview';

function AppHeader({ open, handleDrawerOpen }) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector(getUserDetails);
  const roles = useSelector(getUserRoles);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    dispatch(setToken(null));
  };
  return (
    <AppBar position="fixed" open={open} color="transparent">
      <Toolbar>
        <Box sx={{ display: { xs: 'flex' } }}>
          <AppLogo width={200} height="60" />
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
          <TutorStatusOverview />
        </Box>
        <Box
          sx={{ flexGrow: 3, display: { xs: 'flex' }, width: '250px' }}
          onClick={() => navigate('f/comments')}
        >
          <CommentOverview />
        </Box>
        <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
          {/* <IconButton
            size="large"
            sx={{ mx: 2 }}
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            // onClick={handleMenu}
            color="inherit"
          >
            <Icon color="primary" component={NotificationsOutlined} />
          </IconButton> */}

          <ListItem onClick={handleOpenUserMenu}>
            <ListItemAvatar>
              <Avatar
                sx={{ bgcolor: 'primary.main' }}
                alt="No Image"
                // src="/static/images/avatar/2.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ fontFamily: 'fontMedium' }}
              primary={formatName(userDetails)}
              secondary={`${(roles || [{ description: '' }])[0].description} 
              (${(roles || [{ fevTutorRoleName: '' }])[0].fevTutorRoleName})`}
            />
          </ListItem>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            keepMounted
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              sx={{ width: '200px' }}
              key="setting"
              onClick={() => {
                navigate('/f/profile');
                handleCloseUserMenu();
              }}
            >
              <Typography textAlign="center">Profile</Typography>
            </MenuItem>
            <Divider />
            <MenuItem key="logout" onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

AppHeader.propTypes = {
  open: PropTypes.bool,
  handleDrawerOpen: PropTypes.func.isRequired,
};

export default AppHeader;
