/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function ManagementIcon(props) {
  return (
    <SvgIcon
      width="16"
      height="17"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={props.color}
        d="M6.15385 0C3.75385 0 1.84615 1.90769 1.84615 4.30769C1.84195 5.01346 2.01221 5.70933 2.34181 6.33341C2.67141 6.9575 3.15012 7.49047 3.73538 7.88492C1.54462 8.82831 0 11.0105 0 13.5385H1.23077C1.23077 10.8308 3.44615 8.61539 6.15385 8.61539C8.55385 8.61539 10.4615 6.70769 10.4615 4.30769C10.4615 1.90769 8.55385 0 6.15385 0ZM6.15385 1.23077C7.87692 1.23077 9.23077 2.58462 9.23077 4.30769C9.23077 6.03077 7.87692 7.38462 6.15385 7.38462C4.43077 7.38462 3.07692 6.03077 3.07692 4.30769C3.07692 2.58462 4.43077 1.23077 6.15385 1.23077ZM11.1385 8V9.29231C10.7692 9.35385 10.4 9.53846 10.0923 9.72308L9.16923 8.8L8.30769 9.66154L9.23077 10.5846C8.98462 10.8923 8.86154 11.2615 8.8 11.6923H7.38462V12.9231H8.67692C8.73846 13.2923 8.92308 13.6615 9.10769 14.0308L8.18462 14.9538L9.04615 15.8154L9.96923 14.8923C10.2769 15.0769 10.6462 15.2615 11.0154 15.3231V16.6154H12.2462V15.3231C12.6154 15.2615 12.9846 15.0769 13.2923 14.8923L14.2154 15.8154L15.0769 14.9538L14.1538 14.0308C14.4 13.7231 14.5231 13.3538 14.5846 12.9231H16V11.6923H14.7077C14.6462 11.3231 14.4615 10.9538 14.2769 10.5846L15.2 9.66154L14.3385 8.8L13.4154 9.72308C13.1077 9.53846 12.7385 9.35385 12.3692 9.29231V8H11.1385ZM11.6923 10.4615C12.7385 10.4615 13.5385 11.2615 13.5385 12.3077C13.5385 13.3538 12.7385 14.1538 11.6923 14.1538C10.6462 14.1538 9.84615 13.3538 9.84615 12.3077C9.84615 11.2615 10.6462 10.4615 11.6923 10.4615ZM11.6923 11.6923C11.6141 11.6933 11.537 11.7113 11.4665 11.7452C11.3932 11.78 11.3266 11.8273 11.2695 11.8849C11.2119 11.942 11.1647 12.0086 11.1298 12.0818C11.096 12.1524 11.0779 12.2295 11.0769 12.3077C11.0769 12.5385 11.2498 12.7692 11.4665 12.8702C11.537 12.904 11.6141 12.9221 11.6923 12.9231C12 12.9231 12.3077 12.6154 12.3077 12.3077C12.3077 12 12 11.6923 11.6923 11.6923Z"
      />
    </SvgIcon>
  );
}

export default ManagementIcon;
