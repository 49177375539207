import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import React, { useContext } from 'react';
import { SnackbarContext } from '../contexts/Snackbar/SnackbarContext.Context';

function SnackbarMui() {
  const snackbarCtx = useContext(SnackbarContext);
  const { msg, isDisplayed, severity, onClose } = snackbarCtx;

  const handleClose = () => {
    onClose();
  };

  return (
    <Snackbar
      open={isDisplayed}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <Alert variant="filled" onClose={handleClose} severity={severity || 'success'}>
        {msg || ''}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarMui;
