/* eslint-disable no-unsafe-optional-chaining */
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { SESSION_STATUS } from './constants/constants';
import { formatName } from './utils/format';

const utc = require('dayjs/plugin/utc');
const duration = require('dayjs/plugin/duration');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.duration({ months: 12 });
dayjs.extend(utc);

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => descendingComparator(a, b, orderBy);
  }
  return (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getBgColorFromStatus = (status) => {
  switch (status) {
    case SESSION_STATUS.ACTIVE:
    case SESSION_STATUS.LIVE:
    case 'Available':
      return '#F0FFF8';
    case SESSION_STATUS.INACTIVE:
    case SESSION_STATUS.DISCONNECTED:
    case SESSION_STATUS.UNKNOWN:
    case 'Offline':
      return '#FFF0F0';
    case SESSION_STATUS.UNASSIGNED:
    case 'Busy':
    default:
      return '#fff7df';
  }
};

export const getTitleColorFromStatus = (status) => {
  switch (status) {
    case SESSION_STATUS.ACTIVE:
    case SESSION_STATUS.LIVE:
    case 'Available':
      return '#18AB56';
    case SESSION_STATUS.INACTIVE:
    case SESSION_STATUS.DISCONNECTED:
    case SESSION_STATUS.UNKNOWN:
    case 'Offline':
      return '#EB5757';
    case SESSION_STATUS.UNASSIGNED:
    case 'Busy':
    default:
      return '#FBBC05';
  }
};

export function toServerDate(date, format) {
  return dayjs.utc(date || new Date()).format(format);
}

export function toLocalDate(date, format = 'DD MM, YYYY') {
  return dayjs(date).format(format);
}

export const getTimeFromMinute = (m) => {
  const num = m;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return `${rhours || '00'}h ${rminutes || '00'}m`;
};

/** @deprecated use formatName function */
export const getUserName = (obj) => {
  if (obj.userName !== '') {
    return obj.userName;
  }
  return `${obj.firstName} ${obj.lastName}`;
};

export const getNamesFromList = (list) => {
  const names = list?.map((v) => formatName(v));
  if (names?.length > 1) {
    return `${names[0]}, ${list?.length - 1}+`;
  }
  return names ? names[0] : 'NA';
};

export const getSortValue = (array) => {
  if (array?.length <= 0) return {};
  return {
    field: array[0]?.id,
    direction: array[0]?.desc ? 'desc' : 'asc',
  };
};

export const getTableSortValue = (obj) => {
  if (isEmpty(obj)) return [];
  return [{ id: obj?.field, desc: obj?.direction === 'desc' }];
};

export function generateDefaultTimeSlots() {
  const x = {
    slotInterval: 30,
    startTime: '00:00',
    endTime: '24:00',
  };
  let slotStartTime = dayjs(x.startTime, 'HH:mm');

  const endTime = dayjs(x.endTime, 'HH:mm');
  let slotEndTime = dayjs(x.startTime, 'HH:mm').add(30, 'minutes');

  const allFixedSlots = [];

  while (slotStartTime < endTime) {
    const createdSlot = `${slotStartTime.format('HH:mm:ss')}-${slotEndTime.format('HH:mm:ss')}`;
    allFixedSlots.push(createdSlot);
    slotStartTime = slotStartTime.add(x.slotInterval, 'minutes');
    slotEndTime = slotEndTime.add(x.slotInterval, 'minutes');
  }
  return allFixedSlots;
}

export const generateDefaultDatesOfMonth = (newValue = dayjs()) => {
  let daysInMonth = newValue.daysInMonth();
  const arrDays = [];
  while (daysInMonth) {
    const current = dayjs(newValue.format('YYYY-MM-DD')).date(daysInMonth);
    arrDays.push(current.format('YYYY-MM-DD'));
    daysInMonth--;
  }
  return arrDays.reverse();
};

export function enumerateDaysBetweenDates({ startDate, endDate }) {
  const dates = [];
  while (dayjs(startDate) <= dayjs(endDate)) {
    dates.push(startDate);
    startDate = dayjs(startDate).add(1, 'days').format('YYYY-MM-DD');
  }
  return dates;
}
