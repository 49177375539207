export const globalStyles = {
  tablePage: {
    height: '100%',
    width: '100%',
    // justifyContent: 'left',
    // display: 'flex',
    // flexDirection: 'column',
  },
  stickyCol: {
    position: 'sticky',
    left: 0,
    background: 'white',
    // boxShadow: '5px 2px 5px grey',
    borderRight: '1px solid #dcdcdc',
    zIndex: 999,
  },
};
