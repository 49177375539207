// import './pages/wdyr';
import React, { Suspense, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AppLayout from './layout';
import {
  ADD_TIME_SLOT,
  COMMENTS_PATH,
  LOGIN_PATH,
  MANAGEMENTS_PATH,
  NOTIFICATIONS_PATH,
  ONGOING_SESSION_PATH,
  PERMISSION_PATH,
  PROFILE_PATH,
  REPORTS_PATH,
  ROLE_MANAGE_PATH,
  SCHEDULE_CALENDAR_PATH,
  SESSION_HISTORY_PATH,
  TUTOR_AVAILABILITY_PATH,
  TUTOR_SHIFT_PATH,
  TUTOR_TEAM_PATH,
  UPCOMING_SESSION_PATH,
} from './layout/routes';
import { useAuthCheck } from './pages/Auth/useAuthCheck.hook';
import NotFoundPage from './pages/NotFound.page';
import { TutorTeamProvider } from './pages/TutorAvailability/components/TutorTeam.context';
import { useGetFilterValuesQuery } from './redux/slices/api/v1/sessions';
import { useGetCurrentUserQuery } from './redux/slices/api/v1/users';
import Loader from './shared/components/Loader';
import SnackbarMui from './shared/components/SnackbarMui';
import { SnackbarContext } from './shared/contexts/Snackbar/SnackbarContext.Context';
import SessionHistory from './pages/TutorAvailability/SessionHistory.page';

const DashboardPage = React.lazy(() => import('./pages/Dashboard/Dashboard.page'));
const UpcomingSessionPage = React.lazy(() => import('./pages/UpcomingSession/page'));
const TutorAvailabilityPage = React.lazy(() => import('./pages/TutorAvailability/page'));
const NotificationPage = React.lazy(() => import('./pages/Notifications/page'));
const OngoingSessionPage = React.lazy(() => import('./pages/OngoingSession/page'));
const ScheduleCalendarPage = React.lazy(() => import('./pages/ScheduleCalendar/page'));
const ManagementPage = React.lazy(() => import('./pages/Management/page'));
const LoginPage = React.lazy(() => import('./pages/Auth/Login.page'));
const CommentPage = React.lazy(() => import('./pages/Comments/page'));
const RoleManagement = React.lazy(() => import('./pages/Management/RoleManagement.page'));
const TutorTeamPage = React.lazy(() => import('./pages/TutorAvailability/TutorTeam.page'));
const PermissionsManagement = React.lazy(() =>
  import('./pages/Management/PermissionsManagement.page')
);
const ProfilePage = React.lazy(() => import('./pages/Profile/page'));
const TutorShiftTimingPage = React.lazy(() =>
  import('./pages/TutorAvailability/TutorShiftTiming.page')
);
const TutorAddTimeSlotPage = React.lazy(() =>
  import('./pages/TutorAvailability/TutorAddTimeSlot.page')
);
const ReportsPage = React.lazy(() => import('./pages/Reports/reportsMain'));
const ReportsFilterPage = React.lazy(() => import('./pages/Reports/page'));

function App() {
  const snackbarCtx = useContext(SnackbarContext);
  const { token } = useAuthCheck();
  return (
    <div className="App">
      {token ? <PrivateRoutes /> : <PublicRoutes />}
      {snackbarCtx.isDisplayed && <SnackbarMui />}
    </div>
  );
}

function PublicRoutes() {
  return (
    <Routes>
      <Route path={LOGIN_PATH} element={<LoginPage />} />
    </Routes>
  );
}

function PrivateRoutes() {
  const { isLoading } = useGetCurrentUserQuery();
  useGetFilterValuesQuery('grade');
  useGetFilterValuesQuery('school');
  useGetFilterValuesQuery('subject');
  if (isLoading) {
    return <Loader overlay />;
  }
  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<Loader />}>
              <DashboardPage />
            </Suspense>
          }
        />
        <Route
          path={NOTIFICATIONS_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <NotificationPage />
            </Suspense>
          }
        />
        <Route
          path={ONGOING_SESSION_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <OngoingSessionPage />
            </Suspense>
          }
        />
        <Route
          path={UPCOMING_SESSION_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <UpcomingSessionPage />
            </Suspense>
          }
        />
        <Route
          path={SCHEDULE_CALENDAR_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <ScheduleCalendarPage />
            </Suspense>
          }
        />
        <Route
          path={TUTOR_AVAILABILITY_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <TutorAvailabilityPage />
            </Suspense>
          }
        />
        <Route
          path={SESSION_HISTORY_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <SessionHistory />
            </Suspense>
          }
        />
        <Route
          path={COMMENTS_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <CommentPage />
            </Suspense>
          }
        />
        <Route
          path={MANAGEMENTS_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <ManagementPage />
            </Suspense>
          }
        />
        <Route
          path={TUTOR_TEAM_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <TutorTeamProvider>
                <TutorTeamPage />
              </TutorTeamProvider>
            </Suspense>
          }
        />
        <Route
          path={ROLE_MANAGE_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <RoleManagement />
            </Suspense>
          }
        />
        <Route
          path={PERMISSION_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <PermissionsManagement />
            </Suspense>
          }
        />
        <Route
          path={PROFILE_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <ProfilePage />
            </Suspense>
          }
        />
        <Route
          path={TUTOR_SHIFT_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <TutorShiftTimingPage />
            </Suspense>
          }
        />
        <Route
          path={ADD_TIME_SLOT}
          element={
            <Suspense fallback={<Loader />}>
              <TutorAddTimeSlotPage />
            </Suspense>
          }
        />
        <Route
          path={REPORTS_PATH}
          element={
            <Suspense fallback={<Loader />}>
              <ReportsPage />
            </Suspense>
          }
        />
        <Route
          exact
          path={`${REPORTS_PATH}/:reportType`}
          element={
            <Suspense fallback={<Loader />}>
              <ReportsFilterPage />
            </Suspense>
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

export default App;
