/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function CommentIcon(props) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.42857 11.76L4.57143 10.6171V12.24L3.25714 13.5429L2.28571 13.1429V10.2857H0.571429L0 9.71428V0.571429L0.571429 0H14.2857L14.8571 0.571429V5.71429H13.7143V1.14286H1.14286V9.14286H2.85714L3.42857 9.71428V11.76ZM10.6171 13.7143L12.7429 15.8286L13.7143 15.4286V13.7143H15.4286L16 13.1429V7.42857L15.4286 6.85714H6.28571L5.71429 7.42857V13.1429L6.28571 13.7143H10.6171ZM10.8571 12.5714H6.85714V8H14.8571V12.5714H13.1429L12.5714 13.1429V14.0457L11.2571 12.7429L10.8571 12.5714Z"
      />
    </SvgIcon>
  );
}

export default CommentIcon;
