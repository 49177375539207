import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${window.REACT_APP_BASE_URL}/api/v1/jwt` }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'authenticate',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const { useLoginMutation } = authenticationApi;
