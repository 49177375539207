export const formControlStyles = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
        borderColor: '#D9D9D9',
        // height: '40px',
        // fontSize: '0.875rem',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'capitalize',
        fontFamily: 'fontSemiBold',
        boxShadow: 'none',
      },
    },
  },
};
