// import dayjs from 'dayjs';
import { Grid } from '@mui/material';
import _ from 'lodash-es';
import React from 'react';

import ILPIcon from '../../assets/icons/ILPIcon';
import NotesIcon from '../../assets/icons/NotesIcon';
// import SwapIcon from '../../assets/icons/SwapIcon';
import ViewIcon from '../../assets/icons/ViewIcon';
import StatusBadge from '../../shared/components/StatusBadge';
import TableAction from '../../shared/components/TableAction.button';
// import { SESSION_STATUS } from '../../shared/constants/constants';
import { formatName } from '../../shared/utils/format';
import { colors } from '../../theme/colors.styles';

export default function upcomingSessionColumns(props) {
  const { dateKey = 'Date', handleRowClick } = props;
  return [
    {
      header: 'Sl.#',
      accessorKey: 'id',
    },
    {
      header: `Start Time`,
      accessorKey: 'startDt',
    },
    { accessorKey: 'sessionId', header: 'Sess.ID' },
    {
      accessorKey: 'sessionStatusType',
      header: 'Status',
      cell: ({ renderValue }) => <StatusBadge label={renderValue()} />,
    },
    {
      accessorKey: 'student',
      header: 'Student Name',
      cell: (info) => _.map(info.getValue(), formatName).join(', '),
    },
    {
      accessorKey: 'tutor',
      header: 'Tutor Name',
      cell: (info) => formatName(info.getValue()),
    },
    { accessorKey: 'school', header: 'School Name' },
    { accessorKey: 'grade', header: 'Grade' },
    { accessorKey: 'subject', header: 'Subject' },
    { accessorKey: 'lesson', header: 'Lesson' },
    {
      id: 'notes',
      header: 'Notes',
      align: 'center',
      cell: () => (
        <TableAction
          title="This is not clickable"
          // eslint-disable-next-line
          // onClick={() => alert('Action: Notes')}
          Icon={NotesIcon}
          viewBox="0 0 22 16"
          fill={colors.IconColor.view}
        />
      ),
    },
    {
      id: 'ilp',
      header: 'ILP',
      align: 'center',
      cell: () => (
        <TableAction
          title="This is not clickable"
          // eslint-disable-next-line
          // onClick={() => alert('Action: ILP')}
          Icon={ILPIcon}
          viewBox="0 0 16 20"
          fill={colors.IconColor.ilp}
        />
      ),
    },
    {
      id: 'action',
      header: 'Action',
      align: 'center',
      cell: ({ row: { original } }) => (
        <Grid container sx={{ width: '100px' }}>
          <Grid item xs={6}>
            <TableAction
              title="This is not clickable"
              // eslint-disable-next-line
              // onClick={() => alert('Action: View')}
              Icon={ViewIcon}
              viewBox="0 0 22 16"
              fill={colors.IconColor.view}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <TableAction
              title="This is not clickable"
              onClick={(e) => {
                original?.sessionStatusType !== SESSION_STATUS.LIVE &&
                  handleRowClick(e, original, 'transferTo');
              }}
              Icon={SwapIcon}
              viewBox="0 0 16 16"
              fill={colors.IconColor.swap}
            />
          </Grid> */}
        </Grid>
      ),
    },
  ];
}
