/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';

import React from 'react';

function CommentIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.11765 18.8235H2.94118L3.64706 17.8824C4.11765 17.2353 4.41177 16.4118 4.58824 15.4118C1.64706 14.0588 0 11.5294 0 8.23529C0 3.17647 3.82353 0 10 0C16.1765 0 20 3.17647 20 8.23529C20 13.4118 16.2353 16.4706 10 16.4706H9.58823C8.64706 17.5882 7 18.8235 4.11765 18.8235ZM10 1.17647C4.47059 1.17647 1.17647 3.82353 1.17647 8.23529C1.17647 12 3.47059 13.7647 5.41177 14.5294L5.82353 14.7059L5.76471 15.1765C5.64706 16.1176 5.47059 16.9412 5.11765 17.6471C7.05882 17.4118 8.17647 16.4118 8.82353 15.5882L9 15.3529H10C17.9412 15.3529 18.8235 10.4118 18.8235 8.29412C18.8235 3.82353 15.5294 1.17647 10 1.17647Z"
          fill="#EA4335"
        />
      </svg>
    </SvgIcon>
  );
}

export default CommentIcon;
