import { Box, Typography } from '@mui/material';
import React from 'react';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 1,
    pb: 1,
    px: 2,
    // pt: 2,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
};

export function EmptyHeader({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {children}
    </Box>
  );
}
const border = '1px solid #0086C3';
function PageHeader({ icon, title, children, containerStyle = {}, borderBottom }) {
  return (
    <Box sx={[styles.container, { borderBottom: borderBottom ? border : null }, containerStyle]}>
      <Box sx={{ ...styles.titleContainer }}>
        {icon}
        <Typography fontFamily="fontBold" fontSize="1.5rem" noWrap marginLeft={2} component="div">
          {title}
        </Typography>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
}

export default PageHeader;
