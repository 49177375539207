import { FormControl, MenuItem, Select } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';

function DropdownSelect(props) {
  const {
    value,
    onChange,
    options,
    label,
    valueKey = 'id',
    labelKey = 'label',
    getLabel,
    variant,
    getValue,
    containerStyle,
    disableLabel = true,
    style,
    ...selectProps
  } = props;
  const renderLabel = useCallback(
    (op) => {
      if (isEmpty(options)) return null;
      if (getLabel) {
        return getLabel(op);
      }
      return labelKey && op[labelKey];
    },
    [getLabel, labelKey, options]
  );

  const renderValue = useCallback(
    (op) => {
      if (isEmpty(options)) return null;
      if (getValue) {
        return getValue(op);
      }
      return valueKey && op[valueKey];
    },
    [getValue, valueKey, options]
  );

  const handleChange = useCallback(({ target }) => {
    const selectedOp = options.find((v) => v[valueKey] === target.value) || target.value;
    onChange(selectedOp);
  }, []);

  return (
    <FormControl variant={variant} size="small" sx={containerStyle}>
      <Select
        value={value}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        {...style}
        {...selectProps}
      >
        {label && (
          <MenuItem disabled={disableLabel} value={renderValue({ value: null })}>
            <em>{label}</em>
          </MenuItem>
        )}
        {options?.map((v, k) => (
          <MenuItem key={`op-${k}`} value={renderValue(v)}>
            {renderLabel(v)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DropdownSelect;
