import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
// import DialogContent from '@mui/material/DialogContent'
// import DialogActions from '@mui/material/DialogActions'
import { HighlightOffRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
// import AcUnitIcon from '@mui/icons-material/AcUnit';
// import Typography from '@mui/material/Typography'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, IconComponent, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {IconComponent || null}
        {children}
      </Box>

      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <HighlightOffRounded />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  modalIcon: PropTypes.element,
};

export default function CustomModal(props) {
  const { open, close, title, children, modalIcon: IconComponent, ...rest } = props;
  return (
    <BootstrapDialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...rest}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={close}
        IconComponent={IconComponent}
      >
        <Typography variant="h6" fontFamily="fontBold" noWrap component="div">
          {title || ''}
        </Typography>
      </BootstrapDialogTitle>
      {children}
    </BootstrapDialog>
  );
}
CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  modalIcon: PropTypes.element,
};
