import dayjs from 'dayjs';

const withPadding = (value) => `00${value}`.slice(-2);

export const transformToLocalTz = (
  { date: [year, month, day], time: [hours, minutes] },
  format = 'DD/MM/YYYY HH:mm'
) => {
  const formattedDate = `${year}-${withPadding(month)}-${withPadding(day)}T${withPadding(
    hours
  )}:${withPadding(minutes)}:00.000Z`;

  return dayjs(formattedDate).format(format);
};

export const formatName = (
  { firstName = 'N', lastName = 'A' } = { firstName: 'N', lastName: 'A' }
) => `${firstName} ${lastName}`;
